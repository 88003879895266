import { PaginationData, request } from '@/util/request';
import { message } from 'ant-design-vue';

interface CommentItem {
    avatar?: string;
    check_status?: number;
    content?: string;
    create_time?: number;
    delivery_star?: number;
    id?: number;
    is_anon?: number;
    is_show?: number;
    is_top?: number;
    level?: number;
    nickname?: string;
    order_id?: number;
    product_id?: number;
    product_image?: string;
    product_star?: number;
    product_title?: string;
    register_id?: number;
    replier_name?: string;
    reply_content?: string;
    reply_time?: number;
    update_time?: number;
}

/**获取评论列表 */
export async function getCommentList(params?: {
    current?: number;
    start_time?: number;
    end_time?: number;
    order_number?: number;
    product_title?: string;
    is_top?: 1 | 0;
    product_star?: number;
    delivery_star?: number;
    check_status?: 1 | 0;
}) {
    const url = `/boss/product/comment`;
    const res = await request.get<PaginationData<CommentItem>>(url, {
        params,
    });
    if (res.status == 200 && res.datas) {
        return res.data;
    }
    return res.data;
}
/**
 * 评价审核
 * @param params
 * @returns 成功返回true 失败返回false
 */
export async function commentCheck(params: { check_status: number; id: number }) {
    const url = `/boss/product/comment/check`;
    const res = await request.post(url, params);
    if (res.status == 200 && res.data) {
        message.success('审核成功');
        return true;
    }
    message.error(res?.message || '审核失败');
    return false;
}
/**
 * 置顶
 * @param id
 * @returns 成功返回true 失败返回false
 */
export async function commentTop(id: number, is_top: 1 | 2) {
    const url = `/boss/product/comment/top`;
    const res = await request.post(url, { id, is_top });
    if (res.status == 200 && res.data) {
        message.success('置顶成功');
        return true;
    }
    message.error(res?.message || '置顶失败');
    return false;
}
/**
 * 显示隐藏
 * @param id 
 * @param is_show  true为展开 false为隐藏
 * @returns 成功返回true 失败返回false
 */
export async function commentShow(id: number, is_show: 1 | 2) {
    const url = `/boss/product/comment/show`;
    const res = await request.post(url, { id, is_show });
    if (res.status == 200 && res.data) {
        message.success(`${is_show==1?'公开':''}${is_show==2?'隐藏':''}成功`);
        return true;
    }
    message.error(res?.message || '公开失败');
    return false;
}
/**
 * 回复评论
 * @param id 
 * @param reply_content 
 * @returns 成功返回true 失败返回false
 */
export async function commentReply(id: number, reply_content: string) {
    const url = `/boss/product/comment/reply`;
    const res = await request.post(url, { id, reply_content });
    if (res.status == 200 && res.data) {
        message.success('回复成功');
        return true;
    }
    message.error(res?.message || '恢复失败');
    return false;
}
