import { render, staticRenderFns } from "./NTable.vue?vue&type=template&id=c948483a&scoped=true"
import script from "./NTable.vue?vue&type=script&lang=ts&setup=true"
export * from "./NTable.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./NTable.vue?vue&type=style&index=0&id=c948483a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c948483a",
  null
  
)

export default component.exports